import * as React from "react";
//import { graphql } from "gatsby";
//import { Container, Row, Col } from 'react-bootstrap';

import MemberDetail from "../../components/parts/member-detail";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/member-detail.scss";

const primaryWorks = [];

const ProfileContent = () => {
  return (
    <div className="detail">
      <p>
        フロントエンドを担当しています。サッカーが好きです。宜しくお願いします。
      </p>
    </div>
  );
};

const AwardsContent = () => {
  return null;
};

const MemberDetailPage = ({ location, data }) => {
  //const ogpImage = (!data.ogpImage) ? null : data.ogpImage.publicURL;
  return (
    <MemberDetail
      location={location}
      primaryWorks={primaryWorks}
      profileContent={ProfileContent}
      awardsContent={AwardsContent}
      //ogpImage={ogpImage}
    />
  );
};

export default MemberDetailPage;
/*
export const query = graphql`
  query {
    ogpImage: file(relativePath: {glob: "members/ogp/kudou.*"}) {
      publicURL
    }
  }
`;
*/